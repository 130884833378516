<template>
  <div class="form">
    <section class="form-section" v-if="!useTicketFlag">
      <p class="form-headline">カードデザイン</p>
      <FormRow :need="true">
        <template v-slot:label>カードデザインを選択</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="form-select">
              <select
                :class="{ 'form-control form-select-input': true, 'is-error': errors.cardImage }"
                name="cardImageTemplate"
                v-model="form.cardImageTemplateId"
              >
                <option value="" selected>選択してください</option>
                <option v-for="item in cardTemplateList" :key="item.index" :value="item.id">{{ item.name }}</option>
              </select>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>オリジナルをアップロードする</template>
        <template v-slot:content>
          <div class="form-content-row">
            <ul class="fileUpload">
              <li class="fileUpload-item">
                <Fileupload
                  @uploaded="setFileUpload"
                  @removed="removeFileUpload"
                  :isError="errors.cardImage"
                  :initialUrl="form.cardImageUpload.url"
                  :loading="isUploadLoading.cardImageUpload"
                  statePath="form.cardImageUpload"
                />
              </li>
            </ul>
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>管理画面ロゴをアップロードする</template>
        <template v-slot:content>
          <div class="form-content-row">
            <ul class="fileUpload">
              <li class="fileUpload-item">
                <Fileupload
                  @uploaded="setFileUpload"
                  @removed="removeFileUpload"
                  :initialUrl="form.logo.url"
                  :loading="isUploadLoading.logo"
                  statePath="form.logo"
                />
              </li>
            </ul>
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>ヘッダーロゴをアップロードする</template>
        <template v-slot:content>
          <div class="form-content-row">
            <ul class="fileUpload">
              <li class="fileUpload-item">
                <Fileupload
                  @uploaded="setFileUpload"
                  @removed="removeFileUpload"
                  :initialUrl="form.headerLogo.url"
                  :loading="isUploadLoading.headerLogo"
                  statePath="form.headerLogo"
                />
              </li>
            </ul>
          </div>
        </template>
      </FormRow>
    </section>
    <section class="form-section">
      <p class="form-headline">詳細設定</p>
      <FormRow :need="true">
        <template v-slot:label>支払いサイクル</template>
        <template v-slot:content>
          <div class="form-content-row">
            <ul class="listGrid">
              <li v-for="item of paymentCycles" :key="item.index" class="listGrid-item">
                <label class="form-check form-check-btn">
                  <input class="form-check-input" :disabled="isClientChild" type="checkbox" :value="item.id" name="paymentCycles" v-model="form.paymentCycles" />
                  <span :class="{ 'form-check-label': true, 'is-error': errors.paymentCycles }">{{ item.name }}</span>
                </label>
              </li>
            </ul>
          </div>
        </template>
      </FormRow>
      <FormRow :need="true">
        <template v-slot:label>利用規約のURL</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input
              :class="{ 'form-control': true, 'is-error': errors.termsUrl }"
              type="text"
              name="termsUrl"
              :disabled="isClientChild"
              v-trim
              v-model="form.termsUrl"
            />
          </div>
        </template>
      </FormRow>
      <FormRow :need="true">
        <template v-slot:label>プライバシーポリシーのURL</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input
              :class="{ 'form-control': true, 'is-error': errors.privacyPolicyUrl }"
              type="text"
              name="privacyPolicyUrl"
              :disabled="isClientChild"
              v-trim
              v-model="form.privacyPolicyUrl"
            />
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>特定商取引法の表記のURL</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input
              :class="{ 'form-control': true, 'is-error': errors.lawUrl }"
              type="text"
              name="lawUrl"
              :disabled="isClientChild"
              v-trim
              v-model="form.lawUrl"
            />
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>追加URL</template>
        <template v-slot:subLabel>フロント画面、左上ハンバーガーメニュー内INFOフィールド、フッターの一番下に追加でURLを入れる際、タイトルとURLを共に設定します。</template>
        <template v-slot:content>
          <div class="ml-20">
            <p class="form-label">追加URLタイトル</p>
            <div class="form-content-row">
              <input
                :class="{ 'form-control': true, 'is-error': errors.titleAdditionalUrl }"
                type="text"
                name="titleAdditionalUrl"
                :disabled="isClientChild"
                v-trim
                maxlength="30"
                v-maxlength
                v-model="form.titleAdditionalUrl"
              />
            </div>
            <p class="form-label">追加URLのURL</p>
            <div class="form-content-row">
              <input
                :class="{ 'form-control': true, 'is-error': errors.additionalUrl }"
                type="text"
                name="additionalUrl"
                :disabled="isClientChild"
                v-trim
                v-model="form.additionalUrl"
              />
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>事務局/親企業用FAQのURL</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input
              :class="{ 'form-control': true, 'is-error': errors.urlFaqParent }"
              type="text"
              name="urlFaqParent"
              :disabled="isClientChild"
              v-trim
              v-model="form.urlFaqParent"
            />
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>準事務局/子企業用FAQのURL</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input
              :class="{ 'form-control': true, 'is-error': errors.urlFaqChild }"
              type="text"
              :disabled="isClientChild"
              name="urlFaqChild"
              v-trim
              v-model="form.urlFaqChild"
            />
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>加盟店/店舗グループ用FAQのURL</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input
              :class="{ 'form-control': true, 'is-error': errors.urlFaqStore }"
              type="text"
              :disabled="isClientChild"
              name="urlFaqStore"
              v-trim
              v-model="form.urlFaqStore"
            />
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>イベント終了時のURL</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input
              :class="{ 'form-control': true, 'is-error': errors.urlFaqCustomer }"
              type="text"
              name="urlFaqCustomer"
              v-trim
              :disabled="isClientChild"
              v-model="form.urlFaqCustomer"
            />
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>管理画面からの金額発行許可</template>
        <template v-slot:toggle>
          <label class="form-toggle">
            <input
              class="form-toggle-input"
              type="checkbox"
              name="useCurrencyFlag"
              :checked="form.useCurrencyFlag"
              :disabled="isUseCurrencyFlagDisabled"
              @click="(e) => handleCheckPermission(e, handleChangeUseCurrencyFlag)"
            />
            <span class="form-toggle-set">
              <span class="form-toggle-bg"></span>
              <span class="form-toggle-circle"></span>
            </span>
          </label>
        </template>
      </FormRow>
      <div v-if="form.useCurrencyFlag" class="form-row ml-20">
        <FormRow>
          <template v-slot:label>手動発行した金額に有効期限を付ける</template>
          <template v-slot:content>
            <div class="form-content-row">
              <p>付与されたチャージ金額ごとに利用期間の期限を設ける。</p>
            </div>
            <div class="form-content-row text-red">
              <p>※「選択してください」のままの場合（0ヵ月）の場合は有効期限なしとなります。</p>
            </div>
            <div class="form-content-row ml-20">
              <ul class="listGroup">
                <div class="form-content-row">
                  <button class="d-block link" @click="showModal">有効期限の定義</button>
                </div>
                <transition name="fade">
                  <div>
                    <div class="form-content-row">
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <div class="form-group-item">
                              <div class="form-select">
                                <select class="form-control form-select-input" v-model="form.expirationMonths">
                                  <option value="">選択してください</option>
                                  <option v-for="month in expirationMonthCount" :key="month" :value="month">{{ month }}</option>
                                </select>
                              </div>
                            </div>
                            <div class="form-group-item">ヵ月</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <FormRow v-if="!!form.expirationMonths && form.useCurrencyFlag">
                      <template v-slot:label>有効期限案内（任意）</template>
                      <template v-slot:content>
                        <ul class="listGroup">
                          <li class="listGroup-item listGroup-point">
                            <div class="form-group">
                              <div class="form-group-item">
                                <p>有効期限日より</p>
                              </div>
                              <div class="form-group-item">
                                <input
                                  :class="{ 'form-control': true, 'is-error': errors.sendMailExpireAfterDays }"
                                  type="tel"
                                  v-number
                                  name="sendMailExpireAfterDays"
                                  v-model="form.sendMailExpireAfterDays"
                                />
                              </div>
                              <div class="form-group-item">日前</div>
                            </div>
                          </li>
                        </ul>
                        <div class="form-content-row text-red">
                          <p>
                            ※設定有無関わらず、通貨付与金額失効日の1日前には顧客へメールにて案内します。
                          </p>
                        </div>
                      </template>
                    </FormRow>
                  </div>
                </transition>
              </ul>
            </div>
          </template>
        </FormRow>
        <FormRow>
          <template v-slot:label>キャンセル可能期間</template>
          <template v-slot:content>
            <div class="form-content-row">
              手動発行後から下記の期間が経過するとキャンセルすることができなくなります。<br />
              ※付与日を0日とします。空欄時は無期限です。<br />
              ※ポイント購入時付与を利用の際は、このキャンセル可能期間経過後にポイント付与されることになります。<br />
            </div>
            <div class="form-content-row">
              <div class="form-group">
                <div class="form-group-item">
                  <label v-if="isClientChild">{{ form.daysAllowCancelChargeManual || 0 }}</label>
                  <input
                    v-else
                    :class="{ 'form-control': true, 'is-error': errors.daysAllowCancelChargeManual }"
                    type="tel"
                    name="daysAllowCancelChargeManual"
                    v-number="'integer'"
                    v-model="form.daysAllowCancelChargeManual"
                  />
                </div>
                <div class="form-group-item">日間</div>
              </div>
            </div>
          </template>
        </FormRow>
      </div>
      <FormRow v-if="isUseCpmParentEvent && (childEventDetail.useCpm || (form.useCurrencyFlag && !childEventDetail.useCpm))">
        <template v-slot:label>CPM利用</template>
        <template v-slot:toggle>
          <label class="form-toggle">
            <input
              class="form-toggle-input"
              type="checkbox"
              name="useCpm"
              v-model="form.useCpm"
              :disabled="isEdit && childEventDetail.useCpm"
            />
            <span class="form-toggle-set">
              <span class="form-toggle-bg"></span>
              <span class="form-toggle-circle"></span>
            </span>
          </label>
        </template>
        <template v-slot:content>
          <div class="form-content-row">
            <p>
              子イベントにてCPM利用の可否を行います。<br />
              こちらの子イベントにてCPM利用の可否を行う場合、以上の手動発行した金額に有効期限を付けるにて設定した期間が適用されます。
            </p>
          </div>
        </template>
      </FormRow>
      <FormRow v-if="!useTicketFlag">
        <template v-slot:label>みまもり設定</template>
        <template v-slot:toggle>
          <label class="form-toggle">
            <input
              class="form-toggle-input"
              type="checkbox"
              name="monitoringFlag"
              :checked="form.monitoringFlag"
              :disabled="$permission.isOffice()"
              @click="handleCheckPermission"
            />
            <span class="form-toggle-set">
              <span class="form-toggle-bg"></span>
              <span class="form-toggle-circle"></span>
            </span>
          </label>
        </template>
        <template v-slot:content></template>
      </FormRow>
      <FormRow>
        <template v-slot:label>イベント利用に認証を設ける</template>
        <template v-slot:toggle>
          <label class="form-toggle">
            <input
              class="form-toggle-input"
              type="checkbox"
              name="useAuthentication"
              :checked="form.useAuthentication"
              @click="handleUseAuthenticationFlag"
            />
            <span class="form-toggle-set">
              <span class="form-toggle-bg"></span>
              <span class="form-toggle-circle"></span>
            </span>
          </label>
        </template>
        <template v-slot:content>
          <div class="form-content-row">
            <p>
              あらかじめ発行した登録ID／認証番号を知っている利用者のみ新規会員登録・利用開始が行えるようにする。<br />
              <span class="text-red">※使用できるAPIについては管理者へお問い合わせください。</span>
            </p>
          </div>
        </template>
      </FormRow>
      <template v-if="form.useAuthentication">
        <FormRow>
          <template v-slot:label>APIキー</template>
          <template v-slot:content>
            <div class="form-content-row">
              <input
                :class="{ 'form-control': true, 'is-error': errors.useAuthentication.key }"
                type="text"
                name="useAuthenticationKey"
                v-trim
                v-model="form.useAuthentication.key"
                v-maxlength
                maxlength="128"
              />
            </div>
          </template>
        </FormRow>
        <FormRow>
          <template v-slot:label>認証API</template>
          <template v-slot:content>
            <div class="form-content-row">
              <input
                :class="{ 'form-control': true, 'is-error': errors.useAuthentication.checkApi }"
                type="text"
                name="useAuthenticationCheckApi"
                v-trim
                v-model="form.useAuthentication.checkApi"
                v-maxlength
                maxlength="255"
              />
            </div>
          </template>
        </FormRow>
        <FormRow>
          <template v-slot:label>登録ID無効化API</template>
          <template v-slot:content>
            <div class="form-content-row">
              <input
                :class="{ 'form-control': true, 'is-error': errors.useAuthentication.invalidApi }"
                type="text"
                name="useAuthenticationInvalidApi"
                v-trim
                v-model="form.useAuthentication.invalidApi"
                v-maxlength
                maxlength="255"
              />
            </div>
          </template>
        </FormRow>
      </template>
      <FormRow v-if="!isClientChild && isUsePointFlagParentEvent">
        <template v-slot:label>ポイントを利用する</template>
        <template v-slot:toggle>
          <label class="form-toggle">
            <input
              class="form-toggle-input"
              type="checkbox"
              name="usePointFlag"
              :checked="form.usePointFlag"
              @click="handleUsePointFlag"
            />
            <span class="form-toggle-set">
              <span class="form-toggle-bg"></span>
              <span class="form-toggle-circle"></span>
            </span>
          </label>
        </template>
      </FormRow>
      <FormRow v-if="form.usePointFlag && !isClientChild && isUsePointFlagParentEvent">
        <template v-slot:label>ポイントUIを変更する</template>
        <template v-slot:toggle>
          <label class="form-toggle">
            <input
              class="form-toggle-input"
              type="checkbox"
              name="newUIPointFlag"
              v-model="form.newUIPointFlag"
            />
            <span class="form-toggle-set">
              <span class="form-toggle-bg"></span>
              <span class="form-toggle-circle"></span>
            </span>
          </label>
        </template>
        <template v-slot:content>
          <div class="form-content-row">
            <p>
              イベント画面にてポイントのみの残高の顧客のUIを変更する。
            </p>
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>メール送信設定</template>
        <template v-slot:toggle>
          <label class="form-toggle">
            <input
              class="form-toggle-input"
              type="checkbox"
              name="sendMailFlag"
              v-model="form.sendMailFlag"
              :disabled="!isGmo"
            />
            <span class="form-toggle-set">
              <span class="form-toggle-bg"></span>
              <span class="form-toggle-circle"></span>
            </span>
          </label>
        </template>
        <template v-slot:content>
          <div class="form-content-row">
            <p>
              OFF：通知メールを送信しません。（チャージ開始/イベント終了時一斉メール）
            </p>
          </div>
        </template>
      </FormRow>
    </section>
    <section class="form-section" v-if="!isGmoFormShown">
      <p class="form-headline">GMO-PG情報</p>
      <FormRow :need="true">
        <template v-slot:label>サイトID</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-6">
                <input
                  :class="{
                    'form-control': true,
                    'is-error': errors.multiPaymentKey.siteId,
                  }"
                  type="text"
                  name="siteId"
                  v-trim
                  v-maxlength
                  maxlength="13"
                  v-alphabetAndNumericHalfsize
                  v-model="form.multiPaymentKey.siteId"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :need="true">
        <template v-slot:label>サイトパスワード</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-6">
                <div class="form-password">
                  <input
                    v-trim
                    v-model="form.multiPaymentKey.sitePass"
                    :class="{
                      'form-control form-password-input': true,
                      'is-error': errors.multiPaymentKey.sitePass,
                    }"
                    :type="sitePwdVisible ? 'text' : 'password'"
                    name="sitePass"
                    v-alphabetAndNumericHalfsize
                  />
                  <label class="form-check form-password-toggle">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="sitePwdVisible"
                    />
                    <i class="aikon form-password-icon"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :need="true">
        <template v-slot:label>ショップID</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-6">
                <input
                  :class="{
                    'form-control': true,
                    'is-error': errors.multiPaymentKey.shopId,
                  }"
                  type="text"
                  name="shopId"
                  v-trim
                  v-maxlength
                  maxlength="13"
                  v-alphabetAndNumericHalfsize
                  v-model="form.multiPaymentKey.shopId"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :need="true">
        <template v-slot:label>ショップパスワード</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-6">
                <div class="form-password">
                  <input
                    v-trim
                    v-model="form.multiPaymentKey.shopPass"
                    :class="{
                      'form-control form-password-input': true,
                      'is-error': errors.multiPaymentKey.shopPass,
                    }"
                    v-alphabetAndNumericHalfsize
                    :type="shopPwdVisible ? 'text' : 'password'"
                    name="shopPass"
                  />
                  <label class="form-check form-password-toggle">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="shopPwdVisible"
                    />
                    <i class="aikon form-password-icon"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </template>
      </FormRow>
    </section>
    <section class="form-section" v-if="isVcnShown || isGmo">
      <p class="form-headline">外部連携</p>
      <FormRow v-if="isVcnShown">
          <template v-slot:label>VCN連携を利用する</template>
          <template v-slot:toggle>
            <label class="form-toggle">
              <input
                class="form-toggle-input"
                type="checkbox"
                name="useVcn"
                :checked="form.useVcn"
                :disabled="!isEditableVcn"
                @click="handleUseVcnFlag"
              />
              <span class="form-toggle-set">
                <span class="form-toggle-bg"></span>
                <span class="form-toggle-circle"></span>
              </span>
            </label>
          </template>
          <template v-slot:content>
            <div class="form-content-row">
              <p>
                VCN 連携を利用し、ハウス Payでの購入に応じた加盟店への支払いを行います。<br />
                この設定は親イベントにて VCN 連携が有効になっている場合のみ利用できます。
              </p>
            </div>
          </template>
      </FormRow>
      <template v-if="isGmo">
        <FormRow v-if="identityVerification">
          <template v-slot:label>本人確認連携を利用する</template>
          <template v-slot:toggle>
            <label class="form-toggle">
              <input
                class="form-toggle-input"
                type="checkbox"
                name="identityVerification"
                v-model="form.identityVerification"
              />
              <span class="form-toggle-set">
                <span class="form-toggle-bg"></span>
                <span class="form-toggle-circle"></span>
              </span>
            </label>
          </template>
          <template v-slot:content>
            <div class="form-content-row">
              <p>
                会員登録時、編集時にマイナンバーカードでの認証を利用できるようにします。<br />
                この設定は親イベントにて本人確認連携が有効になっている場合のみ利用できます。
              </p>
            </div>
          </template>
        </FormRow>
        <template v-if="identityVerification && form.identityVerification">
          <div class="form-content-vcn form-row ml-20">
            <FormRow>
              <template v-slot:label>会員登録時本人確認必須要否</template>
              <template v-slot:content>
                <div class="form-content-row">
                  <ul class="listGroup">
                    <li v-for="(item, index) in momentIdentityTypes" :key="index" class="listGroup-item bd-0">
                      <label class="form-radio">
                        <input
                          :class="{ 'form-radio-input': true, 'is-error': errors.typeMomentIdentity }"
                          type="radio"
                          name="typeMomentIdentity"
                          v-model="form.typeMomentIdentity"
                          :value="item.value"
                        />
                        <span class="form-radio-label">{{ item.label }}</span>
                      </label>
                    </li>
                  </ul>
                </div>
              </template>
            </FormRow>
          </div>
        </template>
        <FormRow v-if="useSms">
            <template v-slot:label>SMS認証連携を利用する</template>
            <template v-slot:toggle>
              <label class="form-toggle">
                <input
                  class="form-toggle-input"
                  type="checkbox"
                  name="useSms"
                  v-model="form.useSms"
                />
                <span class="form-toggle-set">
                  <span class="form-toggle-bg"></span>
                  <span class="form-toggle-circle"></span>
                </span>
              </label>
            </template>
            <template v-slot:content>
              <div class="form-content-row">
                <p>
                  マイページにて携帯電話番号でのSMS認証を利用できるようにします。<br />
                  この設定は親イベントにてSMS認証連携が有効になっている場合のみ利用できます。
                </p>
              </div>
            </template>
        </FormRow>
      </template>
    </section>
    <ModalInformation
      :handleCloseModal="closeModal"
      :isInforModalShown="modal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import { get, set } from 'lodash';
import error from '@/mixins/plugin/error';
import password from '@/mixins/plugin/password';
import event from '@/mixins/module/event';
import FormRow from '@/components/FormRow.vue';
import Fileupload from '@/components/Fileupload.vue';
import ModalInformation from '@/components/ModalInformation.vue';
import { isUrl, isExpireDaysWithMinValue, isCharWithMinMaxLength } from '@/helpers/validators';
import { EventConstants } from '@/constants/event';
import modal from '@/mixins/plugin/modal';

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    }
  },
  data: function() {
    return {
      form: {},
      tagName: '',
      sitePwdVisible: false,
      shopPwdVisible: false,
      isUploadLoading: {
        cardImageUpload: false,
        logo: false,
        headerLogo: false,
      },
      prefixs: ['multiPaymentKey', 'useAuthentication'],
      errors: {
        tagName: false,
      },
      expirationMonthCount: EventConstants.EXPIRATION_MONTH_COUNT,
      dynamicType: EventConstants.expirationType.dynamic.value,
      staticType: EventConstants.expirationType.static.value,
    };
  },
  computed: {
    ...mapGetters({
      newEvent: 'event/newEvent',
      childEventDetail: 'event/childEventDetail',
      parentEventDetail: 'event/parentEventDetail',
      cardTemplateList: 'common/cardTemplateList',
      uploadedImage: 'common/uploadedImage',
      paymentCycles: 'common/paymentCycles',
      isClientChild: 'auth/isClientChild',
      isUsePointFlagParentEvent: 'event/isUsePointFlagParentEvent',
      isCommonAllEvent: 'event/isCommonAllEvent',
      isUseCpmParentEvent: 'event/isUseCpmParentEvent',
      useTicketFlag: 'event/useTicketFlag',
      childEventType: 'event/childEventType',
      identityVerification: 'event/identityVerification',
      useSms: 'event/useSms',
    }),
    subdomain() {
      return this.$route.params.subdomain;
    },
    categoriesList() {
      const nameOfCategories = this.convertToArray(this.form.categories).map((item) => item.name);
      const addCategories = this.convertToArray(this.form.addCategories);
      return nameOfCategories.concat(addCategories);
    },
    isEditableVcn() {
      return this.childEventDetail.isEditableVcn || this.parentEventDetail.vcn;
    },
    isGmo() {
      return this.$permission.isGmo();
    },
    isGmoFormShown() {
      return [EventConstants.PAYMENT_EVENT_TYPE, EventConstants.PAYMENT_GIFT_CARD_EVENT_TYPE].includes(this.childEventType);
    },
    isUseCurrencyFlagDisabled() {
      return this.$permission.isOffice() || this.isUseCpmAndCurrencyFlag;
    },
    isVcnShown() {
      return (this.isEdit && this.form.useVcn) || !this.isEdit || this.isGmo
    },
    isUseCpmAndCurrencyFlag() {
      return this.form.useCpm && this.form.useCurrencyFlag;
    },
    momentIdentityTypes() {
      return Object.values(EventConstants.momentIdentityType);
    }
  },
  mixins: [password, error, event, modal],
  validations() {
    let form = {
      cardImage: {},
      paymentCycles: {},
      termsUrl: { isUrl },
      privacyPolicyUrl: { isUrl },
      lawUrl: { isUrl },
      titleAdditionalUrl: { maxLength: maxLength(30) },
      additionalUrl: { isUrl },
      urlFaqParent: { isUrl },
      urlFaqChild: { isUrl },
      urlFaqStore: { isUrl },
      urlFaqCustomer: { isUrl },
      sendMailExpireAfterDays: {},
      useAuthentication: {},
      multiPaymentKey: {
        siteId: { characterWithMinLength: minLength(13) },
        sitePass: { characterWithMinMaxLength: isCharWithMinMaxLength(8,64) },
        shopId: { characterWithMinLength: minLength(13) },
        shopPass: { characterWithMinMaxLength: isCharWithMinMaxLength(8,64) },
      },
      typeMomentIdentity: {}
    }
    if (this.form?.expirationMonths && this.form?.sendMailExpireAfterDays) {
      form.sendMailExpireAfterDays = { numberMaxLength: maxLength(10), validMailAfterDaysCharge: isExpireDaysWithMinValue(2) };
    }
    if (this.form.useAuthentication) {
      form.useAuthentication = {
        key: { required, maxLength: maxLength(128) },
        checkApi: { required, isUrl, maxLength: maxLength(255) },
        invalidApi: { required, isUrl, maxLength: maxLength(255) },
      }
    }
    if (this.isEdit && !this.isEventUnregistered && !this.isGmoFormShown && !this.useTicketFlag) {
      form = {
        ...form,
        statusEventRequired: (val) => {
          let result = true;
          const requiredFieldList = ['privacyPolicyUrl', 'termsUrl', 'cardImage', 'multiPaymentKey.siteId', 'multiPaymentKey.shopId', 'multiPaymentKey.sitePass', 'multiPaymentKey.shopPass']
          requiredFieldList.forEach((field) => {
            if (!get(val, field)) {
              set(this.errors, field, true)
              result = false;
            }
          });
          if (!val.paymentCycles?.length) {
            this.errors.paymentCycles = true;
            result = false;
          }
          if (!result) {
            this.$message.statusEventRequired();
          }
          return result;
        }
      }
    }
    if (this.form.titleAdditionalUrl && !this.form.additionalUrl) {
      form.additionalUrl = { required }
    } else if (this.form.additionalUrl && !this.form.titleAdditionalUrl) {
      form.titleAdditionalUrl = { required }
    }
    if (this.identityVerification && this.form.identityVerification) {
      form.typeMomentIdentity = { selectRequired: required };
    }
    return { form };
  },
  components: {
    FormRow,
    Fileupload,
    ModalInformation,
  },
  watch: {
    'form.newUIPointFlag'(newVal, oldVal) {
      if (this.form?.monitoringFlag && !oldVal && newVal) {
        this.$message.showError('notChangeOnMonitoringAndUiFlag');
        this.$nextTick(() => {
          this.form.newUIPointFlag = false;
        });
      }
    },
    'form.expirationType'(newVal, oldVal) {
      if (newVal && oldVal && newVal !== oldVal) {
        this.form.expirationMonths = '';
        this.form.sendMailExpireAfterDays = null;
        this.errors.sendMailExpireAfterDays = false;
      }
    },
    'form.expirationMonths'(val) {
      if (!val) {
        this.form.expirationMonths = '';
        this.form.sendMailExpireAfterDays = null;
        this.errors.sendMailExpireAfterDays = false;
      }
    },
    'form.useCurrencyFlag'(val) {
      if (!val) {
        this.form.expirationType = null;
        this.form.expirationMonths = '';
      }
    }
  },
  methods: {
    initForm() {
      this.form = {
        privacyPolicyUrl: '',
        lawUrl: '',
        termsUrl: '',
        multiPaymentKey: {},
        cardImageTemplateId: '',
        cardImageUpload: {},
        logo: {},
        headerLogo: {},
        paymentCycles: [],
        monitoringFlag: false,
        useCurrencyFlag: false,
        useAuthentication: null,
        expirationMonths: '',
        addCategories: [],
        categories: [],
        deleteCategories: [],
        useVcn: false,
        usePointFlag: false,
        newUIPointFlag: false,
        sendMailFlag: true,
        useCpm: false,
        sendMailExpireAfterDays: null,
        expirationType: null,
        titleAdditionalUrl: null,
        additionalUrl: null,
        urlFaqParent: null,
        urlFaqChild: null,
        urlFaqStore: null,
        urlFaqCustomer: null,
        daysAllowCancelChargeManual: null,
        typeMomentIdentity: null,
        useSms: false,
        identityVerification: false,
      }
    },
    convertToArray(arr) {
      return arr || []
    },
    addTag: function() {
      this.$message.reset();
      if (this.tagName === '') {
        this.errors.tagName = true;
        this.$message.noSelectedCategory();
      } else if (this.categoriesList.length >= 15) {
        this.$message.showError('maxCategory', 'max', 15);
      } else if (this.categoriesList.includes(this.tagName)) {
        this.errors.tagName = true;
        this.$message.showError('duplicateCategory');
      } else if (this.tagName.length > 12) {
        this.errors.tagName = true;
        this.$message.showError('categoryNameMaxLength', 'max', 12);
      } else {
        this.errors.tagName = false;
        this.form.addCategories.push(this.tagName);
        this.tagName = '';
      }
    },
    deleteTag: function(tagName) {
      const addCategoryIndex = this.form.addCategories.findIndex(item => item === tagName);
      const categoryIndex = this.form.categories?.findIndex(item => item.name === tagName);
      if (categoryIndex !== -1) {
        this.form.deleteCategories.push(this.form.categories[categoryIndex]?.id);
        this.form.categories.splice(categoryIndex, 1);
      } else {
        this.form.addCategories.splice(addCategoryIndex, 1);
      }
    },
    setFileUpload: async function(url, statePath) {
      const [state, propertyOfState] = statePath.split('.');
      this.isUploadLoading[propertyOfState] = true;
      this[state][propertyOfState].id = await this.$store.dispatch('common/uploadImage', {
        image: url,
      });
      this[state][propertyOfState].url = url;
      this.isUploadLoading[propertyOfState] = false;
    },
    removeFileUpload: function(statePath) {
      const [state, propertyOfState] = statePath.split('.');
      this[state][propertyOfState] = {};
    },
    validate() {
      this.form.cardImage = this.convertCardImage(this.form);
      this.form.sendMailFlag = this.form.sendMailFlag ? 1 : 0;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'event');
        return false
      } else {
        if (this.isEdit) {
          this.$store.commit('event/SET_CHILD_EVENT_DETAIL', this.form)
        } else {
          this.$store.commit('event/SET_NEW_CHILD_EVENT', this.form)
        }
        return true
      }
    },
    async getDetail() {
      if (this.isEdit && this.childEventDetail.directory !== this.$route.params.directory) {
        await this.$store.dispatch('event/getChildEventDetail');
      }
    },
    async getParentEventInfo() {
      await this.$store.dispatch('event/getParentEventDetail', this.$route.params.subdomain);
    },
    handleChangeUseCurrencyFlag(e) {
      if (this.isUseCpmAndCurrencyFlag) {
        e.target.checked = true
        this.$message.showError('notOpenUseCurrencyFlagWithCPM');
      }
      this.form.useCurrencyFlag = e.target.checked;
      if (!this.form.useCurrencyFlag) this.form.sendMailExpireAfterDays = null;
    },
    handleCheckPermission(e, callback) {
      if (callback) {
        callback(e);
      } else if (this.$permission.isOffice()) {
        this.$message.showError('onlySettingWithMaster');
        e.target.checked = !e.target.checked;
      } else if (this.form.newUIPointFlag && !callback) {
        this.$message.showError('notChangeOnMonitoringAndUiFlag');
        e.target.checked = !e.target.checked;
      } else {
        this.form[e.target.name] = e.target.checked;
      }
    },
    handleUseAuthenticationFlag(e) {
      if (e.target.checked) {
        this.form.useAuthentication = {};
      } else {
        this.form.useAuthentication = null;
      }
    },
    handleUsePointFlag(e) {
      if (e.target.checked) {
        this.form.usePointFlag = true;
      } else {
        if (this.isCommonAllEvent) {
          this.$message.showError('notChangeOffIfCommonAllEvent');
          e.target.checked = true;
          this.form.usePointFlag = true;
        } else if (this.isEdit && !this.isCommonAllEvent && this.childEventDetail?.usePointFlag) {
          this.$message.showError('notChangeOffUsePointFlag');
          e.target.checked = true;
          this.form.usePointFlag = true;
        } else {
          this.form.usePointFlag = false;
          this.form.newUIPointFlag = false;
        }
      }
    },
    handleUseVcnFlag(e) {
      if (!this.isEditableVcn) {
        this.$message.showError('notEditChildEventVcn');
        e.target.checked = !e.target.checked;
      } else {
        this.form.useVcn = e.target.checked;
      }
    }
  },
  created() {
    const loading = this.$loading.show();
    this.initForm();
    if (!this.isEdit && !this.newEvent.name) {
      this.$router.push({ name: 'SettingEventChildRegisterBase' })
    }
    Promise.all([
      this.getDetail(),
      this.getParentEventInfo(),
      this.$store.dispatch('common/getCardTemplateList'),
      this.$store.dispatch('common/getPaymentCycles'),
    ]).then(() => {
      if (this.isEdit) {
        this.form = { ...this.form, ...this.childEventDetail };
        this.form.paymentCycles = this.form.paymentCycles?.map((item) => item.id || item) || [];
        this.$store.commit('event/SET_CHILD_EVENT_TYPE', this.form.type);
      } else {
        this.form = { ...this.form, ...this.newEvent }
        this.form.usePointFlag = this.isCommonAllEvent;
      }
      this.initialErrors();
    }).finally(() => {
      this.$loading.clear(loading);
    })
  },
}
</script>
